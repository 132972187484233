import { Dialog, Text } from '@components'
import { Column } from '@containers'
import { MoodForm } from '../MoodForm/MoodForm'

export const MoodDialog = ({ open, onSubmit }) => {
  return (
    <Dialog
      open={open}
      title="Daily Mood"
    >
      <Column sx={{ p: 2, pl: 4, pr: 4, pt: 0 }}>
        <Text>This is the daily recovery log. Please enter the following values:</Text>
        <MoodForm onSubmit={onSubmit} />
      </Column>
    </Dialog>
  )
}
